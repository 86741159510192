<div class="page-container">
    <app-header title="{{ 'self-serve.payrollHighlights.payroll' | translate }}"> </app-header>
    <ui-banner variant="info"><span [innerHTML]="'self-serve.payrollHighlights.banner' | translate"></span></ui-banner>
    <ui-card>
        <div class="title-wrapper margin-bottom-4">
            <img src="/assets/illustrations/new-payroll.svg" class="margin-right-2" />
            <h2>{{ 'self-serve.payrollHighlights.cardTitle' | translate }}</h2>
        </div>
        <main>
            <ul class="list-wrapper">
                <li *ngFor="let highlight of highlights" class="highlight-item">
                    <mat-icon svgIcon="success" class="check-icon"></mat-icon>
                    <span>{{ highlight | translate }}</span>
                </li>
            </ul>

            <img class="highlight-image" src="/assets/img/misc/payroll-highlights.png" />
        </main>
    </ui-card>
</div>
