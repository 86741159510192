import { Component } from '@angular/core';

@Component({
    templateUrl: 'payroll.template.html',
    styleUrls: ['payroll.style.scss'],
})
export class PayrollHighlightsView {
    highlights = [
        'self-serve.payrollHighlights.highlights.craRemittances',
        'self-serve.payrollHighlights.highlights.directDeposit',
        'self-serve.payrollHighlights.highlights.digitalPayStubs',
        'self-serve.payrollHighlights.highlights.automaticT4s',
        'self-serve.payrollHighlights.highlights.automaticRoes',
        'self-serve.payrollHighlights.highlights.ehtCalculation',
        'self-serve.payrollHighlights.highlights.rolesAndPermissions',
        'self-serve.payrollHighlights.highlights.payrollHistory',
        'self-serve.payrollHighlights.highlights.recordsAndReporting',
        'self-serve.payrollHighlights.highlights.managedPayroll',
    ];
}
